/* eslint-disable no-unused-vars */
<template>
  <div>
    <div style="position:absolute; top:0; width:100%; z-index:2;">
      <searchBar></searchBar>
    </div>
    <div style="position:absolute; bottom:0;">
      <v-card></v-card>
    </div>
    <div id="constainer3D" style></div>
    <div id="textOverLay" v-if="WordLabOutput">
      <div
        v-for="cat in WordLabOutput.category"
        :key="cat.label"
        :id="cat.label"
        class="textInfo"
      >{{cat.label}}</div>
      <div id="labelObject" class="labelInfo"></div>
      <div id="dataObject" class="labelInfo"></div>
    </div>

    <!-- <v-container>
      <h1>WordLab</h1>
      <p>
        Wordlab est une représentation 3D de jeu de données destinée à offrir toujours la réponse la plus pertinente lorsqu'un utilisateur cherche par mot clés.
        Ici nous parsons la base d'articles de Bige selon différents axes. Ceci nous permet d'obtenir des réponses pertinentes sur les articles similaires par exemple.
        Il serait simple de rechercher des articles similaires en nous basant sur une catégorie par exemple sauf que ce ne serait pas forcément pertinent d'un point de vue traitement,
        pour Bige l'article le plus proche de l'utilisateur c'est avant tout celui qui traite du sujet le plus similaire.
      </p>
      <v-row v-if="WordLabOutput">
        <v-col>
          <v-card>
            <div id="previsualization" style="width:500px; height:500px;"></div>
          </v-card>
        </v-col>
        <v-col>
          <div>
            <p>Mots : {{Object.keys(WordLabOutput.words).length}}</p>
            <p>Articles : {{Object.keys(WordLabOutput.indexed).length}}</p>
          </div>
        </v-col>
      </v-row>
    </v-container>-->
  </div>
</template>

<script>
// import { mapState } from "vuex";
import * as THREE from "three";

// eslint-disable-next-line no-unused-vars
import { BufferGeometryUtils } from "three/examples/jsm/utils/BufferGeometryUtils.js";
// eslint-disable-next-line no-unused-vars
import gsap from "gsap";
// import { gsap, eaese, Power4 } from "gsap";

// eslint-disable-next-line no-unused-vars
/* import {
  // eslint-disable-next-line no-unused-vars
  VertexNormalsHelper,
  // eslint-disable-next-line no-unused-vars
  GLTFLoader,
  // eslint-disable-next-line no-unused-vars
  VertexTangentsHelper,
  // eslint-disable-next-line no-unused-vars
  BufferGeometryUtils
} from "three"; */

// import * as Highcharts from "highcharts";
// import exporting from "highcharts/modules/exporting.src";
// import highcharts3D from "highcharts/highcharts-3d.src.js";
import { OrbitControls } from "../assets/js/OrbitControl";

import searchBar from "../components/UI/SearchBar";
// import { Vector3 } from "three";

// exporting(Highcharts);
// highcharts3D(Highcharts);

export default {
  name: "searchtraining",
  components: {
    searchBar,
  },
  computed: {
    WordLabOutput() {
      if (this.$store.state.WordLabOutput) {
        return this.$store.state.DB.output;
      }
      // WordLabOutput;
      else return {};
    },
    SearchResult() {
      return this.$store.state.SearchResult;
    },
  },
  data() {
    return {
      data: null,
      searchInput: "",
      camera: null,
      scene: null,
      renderer: null,
      geometry: null,
      material: null,
      mesh: null,
      grid: null,
      divider: 10,
      multiplyer: 2,
      moves: [],
      article: {},
      mouse: new THREE.Vector2(),
    };
  },
  watch: {
    WordLabOutput() {
      setTimeout(
        function () {
          this.setup();
        }.bind(this),
        200
      );
      //  return this.$store.state.DB.output; //WordLabOutput;
    },
    // eslint-disable-next-line no-unused-vars
    SearchResult(newValue, oldValue) {
      if (newValue) {
        this.moveMesh(newValue.result[0].pos);
      }
    },
  },
  mounted: function () {
    this.setup();
  },
  beforeDestroy() {
    document.removeEventListener("mousemove", this.onDocumentMouseMove, true);
  },
  methods: {
    setup: async function () {
      if (this.WordLabOutput && this.$store.state.WordLabOutput) {
        this.setupGraph();
      }
    },
    async setupGraph() {
      /* let cat = [];
      for await (let key of this.WordLabOutput.category) {
        cat.push(key.pos);
      }
      //.forEach(key => cat.push(key.pos));
      let art = [];
      for await (let key of this.WordLabOutput.indexed) {
        art.push(key.pos);
      }
      //.forEach(key => art.push(key.pos));
      let wd = [];
      for await (let key of this.WordLabOutput.words) {
        wd.push(key.pos);
      } */
      // .forEach(key => wd.push(key.pos));
      setTimeout(
        function () {
          // this.displayGraph(cat, art, wd);
          this.show3D(
            this.WordLabOutput.category,
            this.WordLabOutput.indexed,
            this.WordLabOutput.words
          );
          this.animate();
        }.bind(this),
        0
      );
      document.getElementById(
        "dataObject"
      ).innerHTML = `catgérories : ${this.WordLabOutput.category.length}, mots : ${this.WordLabOutput.words.length}, articles : ${this.WordLabOutput.indexed.length}`;
    },
    show3D: function (cat, art, wd) {
      var aspect = window.innerWidth / window.innerHeight;
      var d = 200;
      this.camera = new THREE.OrthographicCamera(
        -d * aspect,
        d * aspect,
        d,
        -d,
        -100,
        100000
      );

      this.scene = new THREE.Scene();
      this.scene.background = new THREE.Color(0xffffff);

      this.drawLines();

      this.material = new THREE.MeshBasicMaterial({ color: 0x2b2b70 });
      this.geometry = new THREE.BoxGeometry(8, 8, 8);

      this.mesh = new THREE.Mesh(this.geometry, this.material);
      this.scene.add(this.mesh);
      this.mesh.position.z = 200;
      this.grid.position.z = 200;
      this.mesh.name = "center";
      // eslint-disable-next-line no-unused-vars
      let geometry = new THREE.SphereGeometry(2, 2, 2);

      geometry = new THREE.SphereGeometry(48, 20, 20);

      for (let category in cat) {
        this.addCategory(cat[category].pos, cat[category].label);
      }

      geometry = new THREE.SphereGeometry(2, 1, 1);

      for (let article of art) {
        this.addArticle(article.pos, article.label);
      }

      //let wordGeometry = [];
      for (let word in wd) {
        this.addWord(wd[word].pos, wd[word].label);
        //wordGeometry.push(this.getWord(wd[word].pos, word));
      }

      this.renderer = new THREE.WebGLRenderer({ antialias: true });
      this.renderer.setSize(window.innerWidth, window.innerHeight);

      /* UNCOMMENT TO DISPLAY JOINTURE LINE OBJECT
      
      var material = new THREE.LineBasicMaterial({
        color: 0xcccccc,
        linecap: "round"
      });
      var points = [];
      for (let word of art) {
        points.push(
          new THREE.Vector3(
            (word.pos[0] / this.divider) * this.multiplyer,
            (word.pos[1] / this.divider) * this.multiplyer,
            word.pos[2] / this.divider
          )
        );
      }
      geometry = new THREE.BufferGeometry().setFromPoints(points);
      var line = new THREE.Line(geometry, material);
      // this.scene.add(line);
      this.scene.add(line); */

      document
        .getElementById("constainer3D")
        .appendChild(this.renderer.domElement);

      // this.renderer.domElement.addEventListener("click", onclick, true);

      gsap.to(this.camera.position, {
        x: 300,
        y: 300,
        z: 500,
        duration: 3,
        delay: 2,
        eaese: "power4.inOut",
      });

      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.target = this.mesh.position;
      this.controls.minDistance = 10;
      this.controls.maxDistance = 500;
      this.controls.enablePan = false;
    },
    moveMesh: function (pos) {
      if (this.moves.slice(-1).pop() !== pos) {
        if (this.grid) {
          this.drawSpline();
          gsap.to(this.grid.position, {
            x: (pos[0] / this.divider) * this.multiplyer,
            y: (pos[1] / this.divider) * this.multiplyer,
            z: pos[2] / this.divider,
            duration: 1,
            eaese: "power4.out",
          });
          gsap.to(this.mesh.position, {
            x: (pos[0] / this.divider) * this.multiplyer,
            y: (pos[1] / this.divider) * this.multiplyer,
            z: pos[2] / this.divider,
            duration: 1,
            eaese: "power4.out",
          });
          /* 
          this.grid.position.x = this.mesh.position.x =
            (pos[0] / this.divider) * this.multiplyer;
          this.grid.position.y = this.mesh.position.y =
            (pos[1] / this.divider) * this.multiplyer;
          this.grid.position.z = this.mesh.position.z = pos[2] / this.divider; 
          */
        }
      }
    },
    drawSpline: function () {},
    addArticle: function (pos, name) {
      let group = new THREE.Group();

      let material = new THREE.MeshBasicMaterial({
        color: 0xfff666,
        opacity: 0.6,
        wireframe: false,
        transparent: true,
      });
      let cube = new THREE.Mesh(this.geometry, material);
      cube.name = name;
      group.add(cube);

      group.position.x = (pos[0] / this.divider) * this.multiplyer;
      group.position.y = (pos[1] / this.divider) * this.multiplyer;
      group.position.z = pos[2] / this.divider;

      // group.type = "word";
      group.name = name;
      // group.lookAt(this.camera.position);

      this.scene.add(group);
    },
    addWord: function (pos, name) {
      this.scene.add(this.getWord(pos, name));
    },
    // eslint-disable-next-line no-unused-vars
    getWord: function (pos, name) {
      // let group = new THREE.Group();
      let geometry = new THREE.SphereBufferGeometry(2, 2, 2);
      let material = new THREE.MeshBasicMaterial({ color: 0xed4c4c });

      let cube = new THREE.Mesh(geometry, material);
      //group.add(cube);

      cube.position.x = (pos[0] / this.divider) * this.multiplyer;
      cube.position.y = (pos[1] / this.divider) * this.multiplyer;
      cube.position.z = pos[2] / this.divider;
      /* cube.type = "word"; */
      cube.name = name;
      return cube;
    },
    addCategory: function (pos, name) {
      let group = new THREE.Group();

      let geometry = new THREE.SphereGeometry(2, 2, 2);

      let material = new THREE.MeshBasicMaterial({
        color: 0x5761ff,
        opacity: 0.1,
        transparent: true,
      });

      geometry = new THREE.SphereGeometry(48, 20, 20);
      let sphere = new THREE.Mesh(geometry, material);

      group.add(sphere);

      group.position.x = (pos[0] / this.divider) * this.multiplyer;
      group.position.y = (pos[1] / this.divider) * this.multiplyer;
      group.position.z = pos[2] / this.divider;
      group.name = name;
      group.type = "category";

      let circle = this.getCircle(32, 48, 0x5761ff);
      let circle2 = this.getCircle(32, 48, 0x5761ff);
      let circle3 = this.getCircle(32, 48, 0x5761ff);
      // circle.rotation.x = 180;
      circle2.rotation.y = Math.PI / 2;
      circle3.rotation.x = Math.PI / 2;
      group.add(circle);
      group.add(circle2);
      group.add(circle3);

      this.scene.add(group);

      document.addEventListener("mousemove", this.onDocumentMouseMove, true);
    },
    onDocumentMouseMove: function (event) {
      // update the mouse variable
      this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

      var ray = new THREE.Raycaster();
      ray.setFromCamera(this.mouse, this.camera);
      var intersects = ray.intersectObjects(this.scene.children);

      if (intersects.length > 0 && intersects[0].object.name !== "") {
        document.getElementById("labelObject").innerHTML =
          intersects[0].object.name + " + " + intersects.length;
        document.getElementById("labelObject").style.top =
          event.clientY - 30 + "px";
        document.getElementById("labelObject").style.left =
          event.clientX -
          document.getElementById("labelObject").offsetWidth / 2 +
          "px";
        document.getElementById("labelObject").style.display = "block";
      } else {
        document.getElementById("labelObject").style.display = "none";
        document.getElementById("labelObject").innerHTML = "";
      }
    },
    getCircle: (segmentCount, radius, color) => {
      // var group = new THREE.Group();
      var geometry2 = new THREE.Geometry(),
        material2 = new THREE.LineBasicMaterial({ color: color });

      for (var i = 0; i <= segmentCount; i++) {
        var theta = (i / segmentCount) * Math.PI * 2;
        geometry2.vertices.push(
          new THREE.Vector3(
            Math.cos(theta) * radius,
            Math.sin(theta) * radius,
            0
          )
        );
      }

      return new THREE.Line(geometry2, material2);
    },
    // eslint-disable-next-line no-unused-vars
    onclick: function (e) {},
    drawLines: function () {
      this.grid = new THREE.Group();

      var material = new THREE.LineBasicMaterial({
        color: 0xcccccc,
        linecap: "round",
      });
      for (let i = 0; i < 21; i++) {
        var points = [];
        points.push(new THREE.Vector3(-1000, 0, 0));
        points.push(new THREE.Vector3(1000, 0, 0));

        var geometry = new THREE.BufferGeometry().setFromPoints(points);
        var line = new THREE.Line(geometry, material);
        line.position.z = (2000 / 20) * i - 1000;
        // this.scene.add(line);
        this.grid.add(line);
      }

      for (let i = 0; i < 21; i++) {
        points = [];
        points.push(new THREE.Vector3(0, 0, -1000));
        points.push(new THREE.Vector3(0, 0, 1000));
        geometry = new THREE.BufferGeometry().setFromPoints(points);
        line = new THREE.Line(geometry, material);
        line.position.x = (2000 / 20) * i - 1000;
        // this.scene.add(line);
        this.grid.add(line);
      }
      material = new THREE.LineBasicMaterial({
        color: 0x2b2b70,
        linecap: "round",
      });

      points = [];
      points.push(new THREE.Vector3(100, 0, 0));
      points.push(new THREE.Vector3(100, 0, 100));
      points.push(new THREE.Vector3(0, 0, 100));
      points.push(new THREE.Vector3(-100, 0, 0));
      points.push(new THREE.Vector3(-100, 0, -100));
      points.push(new THREE.Vector3(0, 0, -100));
      points.push(new THREE.Vector3(100, 0, 0));
      geometry = new THREE.BufferGeometry().setFromPoints(points);
      line = new THREE.Line(geometry, material);
      line.position.y = 1;
      this.grid.add(line);

      let blackMaterial = new THREE.MeshBasicMaterial({ color: 0x2b2b70 });
      geometry = new THREE.BoxGeometry(1, 400, 1);
      line = new THREE.Mesh(geometry, blackMaterial);
      this.grid.add(line);
      geometry = new THREE.BoxGeometry(400, 1, 1);
      line = new THREE.Mesh(geometry, blackMaterial);
      this.grid.add(line);
      geometry = new THREE.BoxGeometry(1, 1, 400);
      line = new THREE.Mesh(geometry, blackMaterial);
      this.grid.add(line);

      this.scene.add(this.grid);
    },
    animate: function () {
      requestAnimationFrame(this.animate);

      this.controls.target = this.mesh.position;

      this.controls.update();

      for (let cat of this.WordLabOutput.category) {
        if (cat.label && document.getElementById(cat.label) !== null) {
          document.getElementById(cat.label).style.top =
            this.toScreenPosition(this.scene.getObjectByName(cat.label)).y +
            "px";
          document.getElementById(cat.label).style.left =
            this.toScreenPosition(this.scene.getObjectByName(cat.label)).x -
            document.getElementById(cat.label).offsetWidth / 2 +
            "px";
        }
      }

      this.renderer.render(this.scene, this.camera);
    },
    /* displayGraph: function(cat, art, wd) {
      // Give the points a 3D feel by adding a radial gradient
      try {
        Highcharts.setOptions({
          colors: Highcharts.getOptions().colors.map(function(color) {
            return {
              radialGradient: {
                cx: 0.4,
                cy: 0.3,
                r: 0.5
              },
              stops: [
                [0, color],
                [
                  1,
                  Highcharts.color(color)
                    .brighten(-0.2)
                    .get("rgb")
                ]
              ]
            };
          })
        });
        // Set up the chart
        var chart = new Highcharts.Chart({
          chart: {
            renderTo: "previsualization",
            margin: 100,
            type: "scatter3d",
            animation: false,
            options3d: {
              enabled: true,
              alpha: 10,
              beta: 30,
              depth: 250,
              viewDistance: 0,
              fitToPlot: false,
              frame: {
                bottom: { size: 1, color: "rgba(0,0,0,0.02)" },
                back: { size: 1, color: "rgba(0,0,0,0.04)" },
                side: { size: 1, color: "rgba(0,0,0,0.06)" }
              }
            }
          },
          title: {
            text: null
          },
          subtitle: {
            text: null
          },
          plotOptions: {
            scatter: {
              width: 10,
              height: 10,
              depth: 10
            }
          },
          legend: {
            enabled: false
          },
          series: [
            {
              name: "Categories",
              colorByPoint: false,
              accessibility: {
                exposeAsGroupOnly: true
              },
              data: cat
            },
            {
              name: "Words",
              colorByPoint: false,
              accessibility: {
                exposeAsGroupOnly: true
              },
              data: wd
            },
            {
              name: "Articles",
              colorByPoint: false,
              accessibility: {
                exposeAsGroupOnly: true
              },
              data: art
            }
          ]
        });
        // Add mouse and touch events for rotation
        (function(H) {
          function dragStart(eStart) {
            eStart = chart.pointer.normalize(eStart);

            var posX = eStart.chartX,
              posY = eStart.chartY,
              alpha = chart.options.chart.options3d.alpha,
              beta = chart.options.chart.options3d.beta,
              sensitivity = 5, // lower is more sensitive
              handlers = [];

            function drag(e) {
              // Get e.chartX and e.chartY
              e = chart.pointer.normalize(e);

              chart.update(
                {
                  chart: {
                    options3d: {
                      alpha: alpha + (e.chartY - posY) / sensitivity,
                      beta: beta + (posX - e.chartX) / sensitivity
                    }
                  }
                },
                undefined,
                undefined,
                false
              );
            }

            function unbindAll() {
              handlers.forEach(function(unbind) {
                if (unbind) {
                  unbind();
                }
              });
              handlers.length = 0;
            }

            handlers.push(H.addEvent(document, "mousemove", drag));
            handlers.push(H.addEvent(document, "touchmove", drag));

            handlers.push(H.addEvent(document, "mouseup", unbindAll));
            handlers.push(H.addEvent(document, "touchend", unbindAll));
          }
          H.addEvent(chart.container, "mousedown", dragStart);
          H.addEvent(chart.container, "touchstart", dragStart);
        })(Highcharts);
      } catch (err) {
        var r = confirm("AN ERROR AS OCCURED PLEASE RELOAD");
        if (r) window.location.reload();
      }
    } */
    toScreenPosition: function (obj) {
      var vector = new THREE.Vector3();

      var widthHalf = 0.5 * window.innerWidth;
      var heightHalf = 0.5 * window.innerHeight;

      obj.updateMatrixWorld();
      vector.setFromMatrixPosition(obj.matrixWorld);
      vector.project(this.camera);

      vector.x = vector.x * widthHalf + widthHalf;
      vector.y = -(vector.y * heightHalf) + heightHalf;

      return {
        x: vector.x,
        y: vector.y,
      };
    },
  },
};
</script>

<style scoped>
.textInfo {
  position: absolute;
  top: 10px;
  text-align: center;
  z-index: 1;
  display: block;
  color: #5761ff;
  font-weight: bold;
  pointer-events: none;
  -webkit-transform: translateZ(0);
}
.labelInfo {
  padding: 5px;
  background: #5761ff;
  color: white;
  position: absolute;
  top: 60px;
  -webkit-transform: translateZ(0);
}
</style>