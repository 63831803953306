<template>
  <v-container>
    <v-row class centered style="margin:0; padding:0;">
      <v-col
        cols="12"
        :class="{content:true}"
        align="center"
        style="margin:0; padding:0; padding-top:40px; padding-bottom:0px;"
      >
        <v-card class="searchInput" elevation="0">
          <v-hover v-slot:default="{ hover }">
            <div class="content" :elevation="hover ? 24 : 2">
              <v-text-field
                label="Rechercher un postit (...)"
                v-model="filter"
                :value="$store.state.search"
              ></v-text-field>
              <v-img :src="iconSearch" height="48" width="48" contain v-on:error="onImgError" />
            </div>
          </v-hover>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import iconSearch from "@/assets/images/icons/bige-icon-search.svg";

export default {
  data() {
    return {
      filter: "",
      iconSearch: iconSearch
    };
  },
  watch: {
    filter() {
      this.$store.commit("searchArticles", this.filter);
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    onImgError: function(err) {
      this.failed_image = true;
    },
    searchHandler: function() {}
  }
};
</script>